import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Subheading from "./../components/Subheading"
import ResourceCard from "./../components/Cards/ResourceCard"
import ResourceCards from "./../components/Cards/ResourceList"
import { graphql } from "gatsby"
import { first } from "lodash"

export default ({
  data: {
    featured: { nodes: featured },
    resources: { nodes: resources },
  },
}) => (
  <Layout pageInfo={{ pageName: "Resources" }}>
    <SEO title="Resources" keywords={[`gatsby`, `react`, `bootstrap`]} />
    <Subheading title={"Resources"}>
      Explore these fire safety resources to learn more about how to help keep
      your family fire safe.
    </Subheading>
    <ResourceCard isFeatured={true} {...first(featured)} />
    <ResourceCards items={resources} />
  </Layout>
)

export const ResourceQuery = graphql`
  query ResourceQuery {
    resources: allNodePublicResource(
      sort: { order: DESC, fields: [created] }
      filter: { langcode: { eq: "en" } }
      skip: 1
    ) {
      nodes {
        id
        title
        field_vimeo_id
        caption: field_caption
        published: created(formatString: "MMMM D, YYYY")
        link: field_link {
          uri
        }
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    featured: allNodePublicResource(
      sort: { order: DESC, fields: [created] }
      filter: { langcode: { eq: "en" } }
      limit: 1
    ) {
      nodes {
        id
        title
        field_vimeo_id
        caption: field_caption
        published: created(formatString: "MMMM D, YYYY")
        link: field_link {
          uri
        }
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
