import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import "./style.scss"

export default ({ title, children, lg = 6, md = 9, className }) => {
  return (
    <Container
      fluid
      className={className ? `subheading ${className}` : `subheading`}
    >
      <Container>
        <Row>
          <Col lg={lg} md={md} className={"no-padding"}>
            <h1>{title}</h1>
            <p dangerouslySetInnerHTML={{ __html: children }}></p>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
